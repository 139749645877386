import React from 'react';
import { graphql } from 'gatsby';

import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';
import { Downloads } from '@mangoart/gatsby-ui/components/ezagrar';
import DefaultLayout from '../layout/DefaultLayout';
import { Icon } from '@mangoart/gatsby-ui/components/ezagrar/Icon';

const DownloadcenterPage = ({ data, location }) => {
  const { pageData, siteData, downloads, manufacturers } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <PageTitle>
        <Icon icon={content.icon} />
        {content.title}
      </PageTitle>
      <Downloads data={pageData} downloads={downloads} manufacturers={manufacturers} />
    </DefaultLayout>
  );
};

export default DownloadcenterPage;

export const DownloadcenterPageQuery = graphql`
  query DownloadcenterPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageDownloads {
      content {
        title
        icon
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          id
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    manufacturers: allCockpitHersteller {
      edges {
        node {
          id
          name
          logopng {
            publicURL
          }
          logosvg {
            publicURL
          }
          logoai {
            publicURL
          }
          logojpg {
            publicURL
          }
        }
      }
    }
    downloads: allCockpitDownloads {
      edges {
        node {
          id
          title
          category
          image {
            publicURL
          }
          files {
            name
            image {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
